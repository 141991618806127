import images from "./images";
const ardrinks = [
  {
    id: 1,
    name: "Hot Chocolate",
    arabic_name: "الشوكولاتة الساخنة",
    category: "hotchocolate",
    ar_category: "الشوكولاتة الساخنة",
    price: 5000,
    description: "",
    image: images.hot_chocolate,
  },

  {
    id: 2,
    name: "strawberry",
    arabic_name: "مايتي فراولة",
    category: "mighty milkshake",
    ar_category: "مايتي ميلك شيك",
    price: 6000,
    description: "",
    image: images.strawberry,
  },
  {
    id: 3,
    name: "chocolate freezy creamy",
    arabic_name: "مايتي فريزي الشوكولاتة",
    category: "mighty milkshake",
    ar_category: "مايتي ميلك شيك",
    price: 6500,
    description: "",
    image: images.chocolate_freezy_creamy,
  },
  {
    id: 4,
    name: "cookies n cream",
    arabic_name: "مايتي كوكيز اند كريم",
    category: "mighty milkshake",
    ar_category: "مايتي ميلك شيك",
    price: 6500,
    description: "",
    image: images.cookies_n_cream,
  },
  {
    id: 5,
    name: "smores toasted",
    arabic_name: "مايتي سمورز",
    category: "mighty milkshake",
    ar_category: "مايتي ميلك شيك",
    price: 6500,
    description: "",
    image: images.smores_toasted,
  },
  {
    id: 6,
    name: "speculoos milk shake",
    arabic_name: "ميلك شيك السبيكولوس",
    category: "mighty milkshake",
    ar_category: "مايتي ميلك شيك",
    price: 6500,
    description: "",
    image: images.speculoos_milk_shake,
  },

  {
    id: 7,
    name: "Popcorn latte",
    arabic_name: "بوب كورن لاتيه",
    category: "lattes",
    ar_category: "لاتيه",
    price: 4500,
    description: "",
    image: images.popcorn_latte,
  },
  {
    id: 8,
    name: "Spanish latte",
    arabic_name: "سبانش لاتيه",
    category: "lattes",
    ar_category: "لاتيه",
    price: 5500,
    description: "",
    image: images.spanish_latte,
  },
  {
    id: 9,
    name: "creme brulee latte",
    arabic_name: "كريم بروليه لاتيه",
    category: "lattes",
    ar_category: "لاتيه",
    price: 5000,
    description: "",
    image: images.creme_brulee_latte,
  },
  {
    id: 10,
    name: "Oriental latte",
    arabic_name: "أورينتال لاتيه",
    category: "lattes",
    ar_category: "لاتيه",
    price: 5000,
    description: "",
    image: images.oriental_latte,
  },
  {
    id: 11,
    name: "speculoos latte",
    arabic_name: "سبيكولوس لاتيه",
    category: "lattes",
    ar_category: "لاتيه",
    price: 5000,
    description: "",
    image: images.speculoos_latte,
  },
  {
    id: 12,
    name: "classic latte",
    arabic_name: "كلاسيك لاتيه",
    category: "lattes",
    ar_category: "لاتيه",
    price: 4000,
    description: "",
    image: images.classic_latte,
  },

  {
    id: 13,
    name: "rose latte",
    arabic_name: "لاتيه الورد",
    category: "Coffeeontherocks",
    ar_category: "قهوة مثلجة",
    price: 5000,
    description: "",
    image: images.rose_latte,
  },
  {
    id: 14,
    name: "iced coffee mocha",
    arabic_name: "موكا قهوة مثلجة",
    category: "Coffeeontherocks",
    ar_category: "قهوة مثلجة",
    price: 5000,
    description: "",
    image: images.iced_coffee_mocha,
  },
  {
    id: 15,
    name: "double coffee vanilla latte",
    arabic_name: "لاتيه الفانيلا مع القهوة",
    category: "Coffeeontherocks",
    ar_category: "قهوة مثلجة",
    price: 5000,
    description: "",
    image: images.double_coffee_vanilla_latte,
  },
  {
    id: 16,
    name: "iced Spanish latte",
    arabic_name: "سبانش لاتيه",
    category: "Coffeeontherocks",
    ar_category: "قهوة مثلجة",
    price: 5500,
    description: "",
    image: images.iced_Spanish_latte,
  },
  {
    id: 17,
    name: "iced cappuccino",
    arabic_name: "كابتشينو مثلج",
    category: "Coffeeontherocks",
    ar_category: "قهوة مثلجة",
    price: 5000,
    description: "",
    image: images.iced_cappuccino,
  },
  {
    id: 18,
    name: "caramel macchiato latte",
    arabic_name: "كراميل ماكياتو",
    category: "Coffeeontherocks",
    ar_category: "قهوة مثلجة",
    price: 5000,
    description: "",
    image: images.caramel_macchiato_latte,
  },

  {
    id: 19,
    name: "classic mojitos",
    arabic_name: "كلاسيك",
    category: "mojitos",
    ar_category: "الموهيتو",
    price: 4000,
    description: "",
    image: images.classic_mojitos,
  },
  {
    id: 20,
    name: "passion fruit mojitos",
    arabic_name: "ماراكويا",
    category: "mojitos",
    ar_category: "الموهيتو",
    price: 5000,
    description: "",
    image: images.passion_fruit_mojitos,
  },
  {
    id: 21,
    name: "mixed berries mojitos",
    arabic_name: "توت",
    category: "mojitos",
    ar_category: "الموهيتو",
    price: 5000,
    description: "",
    image: images.mixed_berries_mojitos,
  },
  {
    id: 22,
    name: "strawberry mojitos",
    arabic_name: "فراولة",
    category: "mojitos",
    ar_category: "الموهيتو",
    price: 5000,
    description: "",
    image: images.strawberry_mojitos,
  },
  {
    id: 23,
    name: "peach mojitos",
    arabic_name: "دراق",
    category: "mojitos",
    ar_category: "الموهيتو",
    price: 5000,
    description: "",
    image: images.peach_mojitos,
  },
  {
    id: 24,
    name: "orange mojitos",
    arabic_name: "برتقال",
    category: "mojitos",
    ar_category: "الموهيتو",
    price: 5000,
    description: "",
    image: images.orange_mojitos,
  },
  // ///////////////Not in a Excel menu//////////------------------
  // {
  //   id: 25,
  //   name: "chocolate freezy",
  //   arabic_name: "ميلك شيكس",
  //   category: "milkshakes",
  //   ar_category: "ميلك شيكس",
  //   price: 5000,
  //   description: "",
  //   image: images.chocolate_freezy,
  // },
  // {
  //   id: 26,
  //   name: "cookies n cream Milkshakes",
  //   arabic_name: "كوكيز ان كريم",
  //   category: "milkshakes",
  //   ar_category: "ميلك شيكس",
  //   price: 5000,
  //   description: "",
  //   image: images.cookies_n_cream_milkshakes,
  // },
  // /////////////////////////------------------

  {
    id: 27,
    name: "strawberry milkshakes",
    arabic_name: "فراولة",
    category: "milkshakes",
    ar_category: "ميلك شيكس",
    price: 5500,
    description: "",
    image: images.strawberry_milkshakes,
  },

  {
    id: 28,
    name: "espresso solo or lungo",
    arabic_name: "إسبريسو سولو أو لونغو",
    category: "beverages",
    ar_category: "مشروبات",
    price: 2500,
    description: "",
    image: images.espresso_solo_or_lungo,
  },
  {
    id: 29,
    name: "espresso doppio",
    arabic_name: "إسبريسو دوبيو",
    category: "beverages",
    ar_category: "مشروبات",
    price: 4000,
    description: "",
    image: images.espresso_doppio,
  },
  {
    id: 30,
    name: "cafe Americano",
    arabic_name: "كافيه أمريكانو",
    category: "beverages",
    ar_category: "مشروبات",
    price: 4000,
    description: "",
    image: images.cafe_americano,
  },
  {
    id: 31,
    name: "cappuccino",
    arabic_name: "كابتشينو",
    category: "beverages",
    ar_category: "مشروبات",
    price: 5000,
    description: "",
    image: images.cappuccino,
  },
  {
    id: 33,
    name: "caramel macchiato",
    arabic_name: "كراميل ماكياتو",
    category: "beverages",
    ar_category: "مشروبات",
    price: 5000,
    description: "",
    image: images.caramel_macchiato,
  },
  {
    id: 34,
    name: "Turkish coffe",
    arabic_name: "قهوة تركية",
    category: "beverages",
    ar_category: "مشروبات",
    price: 2500,
    description: "",
    image: images.turkish_coffe,
  },
  {
    id: 35,
    name: "tea",
    arabic_name: "شاي",
    category: "beverages",
    ar_category: "مشروبات",
    price: 1500,
    description: "",
    image: images.tea,
  },
  {
    id: 36,
    name: "iced tea",
    arabic_name: "شاي مثلج",
    category: "beverages",
    ar_category: "مشروبات",
    price: 3000,
    description: "",
    image: images.iced_tea,
  },
  {
    id: 37,
    name: "soft drink",
    arabic_name: "مشروبات غازية",
    category: "beverages",
    ar_category: "مشروبات",
    price: 1000,
    description: "",
    image: images.soft_drink,
  },
  {
    id: 38,
    name: "mineral water",
    arabic_name: "مياه معدنية",
    category: "beverages",
    ar_category: "مشروبات",
    price: 500,
    description: "",
    image: images.mineral_water,
  },
  {
    id: 39,
    name: "energy drink",
    arabic_name: "مشروبات طاقة",
    category: "beverages",
    ar_category: "مشروبات",
    price: 3000,
    description: "",
    image: images.energy_drink,
  },

  {
    id: 41,
    name: "pomegranate juice",
    arabic_name: "عصير الرمان",
    category: "fresh juices",
    ar_category: "عصائر طبيعية",
    price: 5500,
    description: "",
    image: images.pomegranate_juice,
  },
  {
    id: 42,
    name: "melon juice",
    arabic_name: "عصير البطيخ",
    category: "fresh juices",
    ar_category: "عصائر طبيعية",
    price: 5000,
    description: "",
    image: images.melon_juice,
  },
  {
    id: 43,
    name: "orange juice",
    arabic_name: "عصير البرتقال",
    category: "fresh juices",
    ar_category: "عصائر طبيعية",
    price: 4500,
    description: "",
    image: images.orange_juice,
  },
  {
    id: 44,
    name: "Lemon & Mint",
    arabic_name: "ليمون ونعنع",
    category: "fresh juices",
    ar_category: "عصائر طبيعية",
    price: 4500,
    description: "",
    image: images.lemon_mint,
  },
];

export default ardrinks;
