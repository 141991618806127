import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const languages = {
    ar: {
        home: "الرئيسية",
        food: "الاطعمة",
        drinks: "المشروبات",
        chocolate: "الشكولاتة",
        shisha: "الشيشة",
        language: "عربي"
    },
    en: {
        home: "Home",
        food: "Food",
        drinks: "Drinks",
        chocolate: "Chocolate",
        shisha: "Shisha",
        language: "English"
    }
}
const NavMenu = () => {
    const [isEnglish, setIsEnglish] = useState(false);

    function checkLanguage() {
        console.log("isEnglish")
        setIsEnglish(!isEnglish)
    }
    return (
        <>
            <div className="home__menu_language">
                <Link to="/" className="home__menu_language-link"
                    onClick={() => checkLanguage()}
                >
                    {isEnglish ?
                        languages.en.language : languages.ar.language}
                </Link>
            </div>
            <ul className='home__menu_language-list'>
                <li className="home__menu_language-list_link">

                    <Link to={isEnglish ? "/foodar" : "/fooden"}  >

                        {isEnglish ? languages.ar.food : languages.en.food}
                    </Link>
                </li>
                <li className="home__menu_language-list_link">
                    <Link to={isEnglish ? "/drinksar" : "/drinksen"}  >
                        {isEnglish ? (languages.ar.drinks) : (languages.en.drinks)}
                    </Link>
                </li>

                <li className="home__menu_language-list_link">
                    <Link to={isEnglish ? "/chocolatear" : "/chocolateen"}  >
                        {isEnglish ?
                            languages.ar.chocolate : languages.en.chocolate}
                    </Link>
                </li>
                <li className="home__menu_language-list_link">
                    <Link to={isEnglish ? "/shishaar" : "/shishaen"}  >
                        {isEnglish ? languages.ar.shisha : languages.en.shisha}
                    </Link>
                </li>
            </ul>
        </>
    )
}

export default NavMenu