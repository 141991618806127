import images from "./images";
const archocolate = [
  {
    id: 1,
    name: "Strawberry banana delight",
    arabic_name: "بانكيك بالموز والفراولة",

    category: "Better Milk Pancake",
    ar_category: "بتر ميلك بانكيك",
    price: "7000",
    description:
      "A delicious combination of fresh strawberries and bananas on top of fluffy pancakes.",
    image: images.strawberry_banana_delight,
  },
  {
    id: 2,
    name: "Sweet and simple",
    arabic_name: "بانكيك كلاسيك",
    category: "Better Milk Pancake",
    ar_category: "بتر ميلك بانكيك",
    price: "6000",
    description: "Classic pancake topped with butter and maple syrup.",
    image: images.sweet_and_simple,
  },
  {
    id: 3,
    name: "Crunchy chocolate pancakes",
    arabic_name: "كرانشي شوكوليت بانكيك",
    category: "Better Milk Pancake",
    ar_category: "بتر ميلك بانكيك",
    price: "7000",
    description: "Fluffy pancakes with chocolate chips and crispy cereal.",
    image: images.crunchy_chocolate_pancakes,
  },
  {
    id: 4,
    name: "Speculoos pancake",
    arabic_name: "سبيكولوس بانكيك",
    category: "Better Milk Pancake",
    ar_category: "بتر ميلك بانكيك",
    price: "8500",
    description:
      "Pancakes topped with speculoos cookie butter and whipped cream.",
    image: images.speculoos_pancake,
  },
  {
    id: 5,
    name: "Dip n dip pancake",
    arabic_name: "ديب ان ديب بانكيك",
    category: "Better Milk Pancake",
    ar_category: "بتر ميلك بانكيك",
    price: "8000 ",
    description:
      "Pancakes topped with melted chocolate and served with a side of fresh fruit.",
    image: images.dip_n_dip_pancake,
  },
  {
    id: 6,
    name: "Pancake brownie club",
    arabic_name: "بانكيك برواني كلوب",
    category: "Better Milk Pancake",
    ar_category: "بتر ميلك بانكيك",
    price: "9500 ",
    description:
      "A decadent combination of fluffy pancakes and rich chocolate brownies.",
    image: images.pancake_brownie_club,
  },

  {
    id: 7,
    name: "Crunchy Speculoos Waffle",
    arabic_name: "كرانشي سبيكولوس وافل",
    category: "Waffles",
    ar_category: "الوافل",
    price: 6500,
    description:
      "Special dip waffle covered with lotus sauce, lotus biscuits and ice cream.",
    image: images.crunchy_speculoos_waffle,
  },
  {
    id: 8,
    name: "Triple Chocolate Waffle",
    arabic_name: "تريبل شوكوليت وافل",
    category: "Waffles",
    ar_category: "الوافل",
    price: 5000,
    description: "Special dip waffle covered with three types of chocolate.",
    image: images.triple_chocolate_waffle,
  },
  {
    id: 9,
    name: "Crunchy Oreo Waffle",
    arabic_name: "كرانشي أوريو وافل",
    category: "Waffles",
    ar_category: "الوافل",
    price: 7000,
    description: "A classic waffle with chunks of Oreo cookies mixed in.",
    image: images.crunchy_oreo_waffle,
  },
  {
    id: 10,
    name: "Alaskan Waffle",
    arabic_name: "وافل ألاسكان",
    category: "Waffles",
    ar_category: "الوافل",
    price: 8000,
    description: "A waffle topped with smoked salmon, cream cheese, and dill.",
    image: images.alaskan_waffle,
  },
  {
    id: 11,
    name: "Waffle Stick",
    arabic_name: "وافل ستيك",
    category: "Waffles",
    ar_category: "الوافل",
    price: 4500,
    description:
      "A handheld waffle on a stick, perfect for on-the-go snacking.",
    image: images.waffle_stick,
  },
  {
    id: 12,
    name: "dipndip Waffle",
    arabic_name: "ديب ان ديب وافل",
    category: "Waffles",
    ar_category: "الوافل",
    price: 8500,
    description:
      "A waffle topped with chocolate, strawberries, and whipped cream.",
    image: images.dipndip_waffle,
  },
  {
    id: 13,
    name: "waffle Fries",
    arabic_name: "وافل فرايز",
    category: "Waffles",
    ar_category: "الوافل",
    price: 5000,
    description: "Served with your choice of chocolate",
    image: images.waffle_fries,
  },
  {
    id: 14,
    name: "Brownies crepe",
    arabic_name: "براونيز كريب",
    category: "Crepes",
    ar_category: "الكريب",
    price: "7000 ",
    description:
      "Thin crepe filled with chocolate brownies and topped with whipped cream and chocolate sauce.",
    image: images.brownies_crepe,
  },
  {
    id: 15,
    name: "Fettuccine crepe",
    arabic_name: "فيتوتشيني كريب",
    category: "Crepes",
    ar_category: "الكريب",
    price: "7500 ",
    description:
      "Thin crepe filled with Nutella and served with fettuccine noodles on top.",
    image: images.fettuccine_crepe,
  },
  {
    id: 16,
    name: "Triple chocolate crepe",
    arabic_name: "تريبل شوكوليت كريب",
    category: "Crepes",
    ar_category: "الكريب",
    price: "5000 ",
    description:
      "Thin crepe filled with chocolate chips and topped with melted chocolate and chocolate sauce.",
    image: images.triple_chocolate_crepe,
  },
  {
    id: 17,
    name: "Crispy crepe",
    arabic_name: "كريسبي كريب",
    category: "Crepes",
    ar_category: "الكريب",
    price: "6500 ",
    description:
      "Thin crepe filled with crispy cereal and topped with whipped cream and caramel sauce.",
    image: images.crispy_crepe,
  },
  {
    id: 18,
    name: "Digestive crepe",
    arabic_name: "دايجستيف كريب",
    category: "Crepes",
    ar_category: "الكريب",
    price: "6500 ",
    description:
      "Thin crepe filled with digestive biscuits and topped with Nutella and whipped cream.",
    image: images.digestive_crepe,
  },
  {
    id: 19,
    name: "Oh my smores",
    arabic_name: "سمورز كريب",
    category: "Crepes",
    ar_category: "الكريب",
    price: "8500 ",
    description:
      "A sweet treat made with graham crackers, marshmallows, and chocolate sauce.",
    image: images.oh_my_smores,
  },
  {
    id: 20,
    name: "Pistachio candy crepe",
    arabic_name: "بيستاشيو كاندي كريب",
    category: "Crepes",
    ar_category: "الكريب",
    price: "10000 ",
    description:
      "A crepe filled with pistachio candy and drizzled with chocolate sauce.",
    image: images.pistachio_candy_crepe,
  },
  {
    id: 21,
    name: "Banana wrap crepe",
    arabic_name: "بنانا راب كريب",
    category: "Crepes",
    ar_category: "الكريب",
    price: "6000 ",
    description: "A crepe filled with sliced bananas and chocolate sauce.",
    image: images.banana_wrap_crepe,
  },
  {
    id: 22,
    name: "Crunchy triple chocolate crepe",
    arabic_name: "كرانشي تريبل شوكوليت كريب",
    category: "Crepes",
    ar_category: "الكريب",
    price: 7500,
    description:
      "A crepe filled with three kinds of chocolate and topped with crunchy bits.",
    image: images.crunchy_triple_chocolate_crepe,
  },
  {
    id: 23,
    name: "Crunchy speculoos crepe",
    arabic_name: "كرانشي سبيكولوس كريب",
    category: "Crepes",
    ar_category: "الكريب",
    price: 7500,
    description:
      "A crepe filled with speculoos spread and topped with crunchy bits.",
    image: images.crunchy_speculoos_crepe,
  },
  {
    id: 24,
    name: "Dip n dip crepe",
    arabic_name: "ديب ان ديب كريب",
    category: "Crepes",
    ar_category: "الكريب",
    price: 8000,
    description:
      "A crepe filled with chocolate spread and topped with fresh fruits.",
    image: images.dip_n_dip_crepe,
  },
  {
    id: 25,
    name: "Mighty oreo crepe",
    arabic_name: "مايتي كريب الأوريو",
    category: "Crepes",
    ar_category: "الكريب",
    price: 10000,
    description:
      "A crepe filled with crushed oreo cookies and drizzled with chocolate sauce.",
    image: images.mighty_oreo_crepe,
  },
  {
    id: 26,
    name: "Mighty speculous crepe",
    arabic_name: "مايتي كريب السبيكولوس",
    category: "Crepes",
    ar_category: "الكريب",
    price: 10000,
    description:
      "A crepe filled with speculoos spread and crushed speculoos cookies.",
    image: images.mighty_speculous_crepe,
  },
  {
    id: 27,
    name: "Mighty crepe",
    arabic_name: "مايتي كريب",
    category: "Crepes",
    ar_category: "الكريب",
    price: 10000,
    description:
      "A crepe filled with your choice of toppings and drizzled with chocolate sauce.",
    image: images.mighty_crepe,
  },

  {
    id: 28,
    name: "Dip n dip chocolate brownie",
    arabic_name: "ديب ان ديب شوكوليت براوني",
    category: "indulgence",
    ar_category: "تغميسات الشوكولاتة",
    price: 6000,
    description:
      "A rich and decadent chocolate brownie served with Dip n Dip's signature dipping sauce.",
    image: images.dip_n_dip_chocolate_brownie,
  },
  {
    id: 29,
    name: "Chocolate fondant",
    arabic_name: "شوكوليت فوندان",
    category: "indulgence",
    ar_category: "تغميسات الشوكولاتة",
    price: 7000,
    description:
      "A warm and gooey chocolate cake with a molten center, served with vanilla ice cream.",
    image: images.chocolate_fondant,
  },
  {
    id: 30,
    name: "The rock star",
    arabic_name: "روك ستار",
    category: "indulgence",
    ar_category: "تغميسات الشوكولاتة",
    price: 7000,
    description:
      "A chocolate-covered ice cream bar with a crunchy biscuit center.",
    image: images.the_rock_star,
  },
  {
    id: 31,
    name: "Cream paff pyramid",
    arabic_name: "كريم باف بيراميد",
    category: "indulgence",
    ar_category: "تغميسات الشوكولاتة",
    price: 8000,
    description:
      "A creamy and indulgent pyramid-shaped dessert made with layers of puff pastry and vanilla cream.",
    image: images.cream_paff_pyramid,
  },
  {
    id: 32,
    name: "The mega jar",
    arabic_name: "ميغا جار",
    category: "indulgence",
    ar_category: "تغميسات الشوكولاتة",
    price: 7000,
    description:
      "A giant jar filled with layers of cake, whipped cream, and chocolate sauce.",
    image: images.the_mega_jar,
  },
  {
    id: 33,
    name: "Fondue indulgence",
    arabic_name: "فوندو إندلجينس",
    category: "indulgence",
    ar_category: "تغميسات الشوكولاتة",
    price: 9000,
    description:
      "A luxurious chocolate fondue with fresh fruits and marshmallows for dipping.",
    image: images.fondue_indulgence,
  },
  {
    id: 34,
    name: "Chocolate and fruit skewers",
    arabic_name: "أسياخ الشوكولاتة والفواكه",
    category: "indulgence",
    ar_category: "تغميسات الشوكولاتة",
    price: "4000 ",
    description: "Fresh fruit skewers drizzled with rich chocolate sauce.",
    image: images.chocolate_and_fruit_skewers,
  },
  {
    id: 35,
    name: "Pain perdu",
    arabic_name: "بان بيردو",
    category: "indulgence",
    ar_category: "تغميسات الشوكولاتة",
    price: "8000 ",
    description:
      "A French-style bread pudding served with caramel sauce and vanilla ice cream.",
    image: images.pain_perdu,
  },
  {
    id: 36,
    name: "Chewy chocolate chip cookies",
    arabic_name: "كوكيز بالشوكولاتة",
    category: "indulgence",
    ar_category: "تغميسات الشوكولاتة",
    price: "5500 ",
    description: "Soft and chewy cookies loaded with chunks of chocolate.",
    image: images.chewy_chocolate_chip_cookies,
  },
  {
    id: 37,
    name: "Caramel cheesecake bars",
    arabic_name: "أصابع تشيزكيك بالكراميل",
    category: "indulgence",
    ar_category: "تغميسات الشوكولاتة",
    price: "9000 ",
    description: "Creamy cheesecake bars swirled with rich caramel sauce.",
    image: images.caramel_cheesecake_bars,
  },
  {
    id: 38,
    name: "Frozen cheesecake pops",
    arabic_name: "تشيزكيك بوب مثلجة",
    category: "indulgence",
    ar_category: "تغميسات الشوكولاتة",
    price: "7000 ",
    description:
      "Creamy cheesecake bites on a stick, frozen and dipped in chocolate.",
    image: images.frozen_cheesecake_pops,
  },
  {
    id: 39,
    name: "Cinnamon and pecan crepe",
    arabic_name: "كريب السينامون والبيكان",
    category: "indulgence",
    ar_category: "تغميسات الشوكولاتة",
    price: 9000,
    description:
      "A thin and delicate crepe filled with cinnamon and pecan crumble.",
    image: images.cinnamon_and_pecan_crepe,
  },
  {
    id: 40,
    name: "Brownies n cookies in a pan",
    arabic_name: "براونيز ان كوكيز ",
    category: "indulgence",
    ar_category: "تغميسات الشوكولاتة",
    price: 7500,
    description:
      "Warm and gooey chocolate brownies and cookies served in a sizzling hot pan.",
    image: images.brownies_n_cookies_in_a_pan,
  },

  {
    id: 41,
    name: "Eclair",
    arabic_name: "اكلير",
    category: "indulgence",
    ar_category: "تغميسات الشوكولاتة",
    price: 4000,
    description:
      "A classic French pastry filled with cream and topped with chocolate.",
    image: images.eclair,
  },
  {
    id: 42,
    name: "The Chocolate Burj",
    arabic_name: "برج الشوكولاتة",
    category: "indulgence",
    ar_category: "تغميسات الشوكولاتة",
    price: 10000,
    description: "A decadent chocolate pastry topped with gold flakes.",
    image: images.the_chocolate_burj,
  },
  {
    id: 43,
    name: "Speculoos n Cookies in a Pan",
    arabic_name: "سبيكولوس ان كوكيز",
    category: "indulgence",
    ar_category: "تغميسات الشوكولاتة",
    price: 7500,
    description:
      "Warm Speculoos cookie topped with vanilla ice cream, chocolate chips and whipped cream.",
    image: images.speculoos_n_cookies_in_a_pan,
  },

  {
    id: 44,
    name: "Crispy Chocolate Ice Cream",
    arabic_name: "كريسبي شوكليت",
    category: "Ice Cream",
    ar_category: "ايس كريم",
    price: 5500,
    description: "Vanilla ice cream with crispy chocolate flakes.",
    image: images.crispy_chocolate_ice_cream,
  },
  {
    id: 45,
    name: "Ice Cream Brownie Sundae",
    arabic_name: "ايس كريم براوني صنداي",
    category: "Ice Cream",
    ar_category: "ايس كريم",
    price: 6000,
    description:
      "Warm brownie topped with vanilla ice cream, whipped cream and chocolate sauce.",
    image: images.ice_cream_brownie_sundae,
  },
  {
    id: 46,
    name: "Strawberry Crispy Chocolate",
    arabic_name: "كرسبي شوكليت ",
    category: "Ice Cream",
    ar_category: "ايس كريم",
    price: 6000,
    description:
      "Vanilla ice cream with fresh strawberries and crispy chocolate flakes.",
    image: images.strawberry_crispy_chocolate_ice_cream,
  },
  {
    id: 47,
    name: "Ice Cream Scoop",
    arabic_name: "سكوب",
    category: "Ice Cream",
    ar_category: "ايس كريم",
    price: 2000,
    description: "One scoop of vanilla or chocolate ice cream.",
    image: images.ice_cream_scoop,
  },
  {
    id: 48,
    name: "Profiterole",
    arabic_name: "بروفيتيرول",
    category: "Ice Cream",
    ar_category: "ايس كريم",
    price: 5000,
    description:
      "A light and fluffy French pastry filled with whipped cream and drizzled with chocolate sauce.",
    image: images.profiterole,
  },
  {
    id: 49,
    name: "Speculoos",
    arabic_name: "سبيكولوس",
    category: "Cakepices",
    ar_category: "قطع الكيك",
    price: 6000,
    description: "Warm Speculoos cake served with vanilla ice cream.",
    image: images.speculoos,
  },
  {
    id: 50,
    name: "Triple Chocolate Mousse",
    arabic_name: "تريبل شوكليت موس",
    category: "Cakepices",
    ar_category: "قطع الكيك",
    price: 6500,
    description:
      "Layers of dark, milk, and white chocolate mousse topped with chocolate shavings.",
    image: images.triple_chocolate_mousse,
  },
  {
    id: 51,
    name: "Oreo Cheesecake",
    arabic_name: "أوريو تشيز كيك",
    category: "Cakepices",
    ar_category: "قطع الكيك",
    price: 6000,
    description: "Creamy cheesecake with chunks of Oreo cookies throughout.",
    image: images.oreo_cheesecake,
  },
  {
    id: 52,
    name: "Fruit Cheesecake",
    arabic_name: "تشيز كيك الفواكه",
    category: "Cakepices",
    ar_category: "قطع الكيك",
    price: 5500,
    description: "Creamy cheesecake topped with fresh fruit.",
    image: images.fruit_cheesecake,
  },
  {
    id: 53,
    name: "Strawberry Cheesecake",
    arabic_name: " تشيزكيك فراولة",
    category: "Cakepices",
    ar_category: "قطع الكيك",
    price: 5500,
    description: "Classic New York-style cheesecake.",
    image: images.cheesecake,
  },

  {
    id: 54,
    name: "Mango Cheesecake",
    arabic_name: "تشيز كيك المانغو",
    category: "Cakepices",
    ar_category: "قطع الكيك",
    price: 5000,
    description: "Creamy cheesecake with a tropical twist of fresh mango.",
    image: images.mango_cheesecake,
  },
  {
    id: 56,
    name: "Sticky Pecan Cinnaroll",
    arabic_name: "ميلك كيك الفواكه",
    category: "Cakepices",
    ar_category: "قطع الكيك",
    price: 8000,
    description:
      "A warm cinnamon roll with gooey caramel, topped with crunchy pecans.",
    image: images.sticky_pecan_cinnaroll,
  },

  {
    id: 57,
    name: "Chocolate Pudding",
    arabic_name: "بودينغ الشوكولاته",
    category: "Cake by Order",
    ar_category: "طلبات الكيك",
    price: 20000,
    description:
      "Rich, velvety chocolate pudding made from the finest ingredients.",
    image: images.chocolate_pudding,
  },
  {
    id: 58,
    name: "Sky High",
    arabic_name: "سكاي هاي",
    category: "Cake by Order",
    ar_category: "طلبات الكيك",
    price: 35000,
    description: "Indulgent layers of fudgy brownie and creamy cheesecake.",
    image: images.sky_high,
  },
  {
    id: 59,
    name: "Double Oreo Cheesecake",
    arabic_name: "دوبل أوريو تشييزكيك",
    category: "Cake by Order",
    ar_category: "طلبات الكيك",
    price: 40000,
    description: "Decadent cheesecake loaded with chunks of Oreo cookies.",
    image: images.double_oreo_cheesecake,
  },

  {
    id: 60,
    name: "Layered Lotus",
    arabic_name: "كيك اللوتس الشهير",
    category: "Cake by Order",
    ar_category: "طلبات الكيك",
    price: 35000,
    description:
      "A stunning cake with layers of delicate lotus sponge and fluffy cream.",
    image: images.layered_lotus,
  },

  {
    id: 61,
    name: "Large Dip n Share Box",
    arabic_name: "بوكس المشاركة الكبير",
    category: "Delivery or takeaway",
    ar_category: "دليفري أو تيك اواي",
    price: 18000,
    description: "A generous assortment of sweet and savory dips for sharing.",
    image: images.large_dip_n_share_box,
  },
  {
    id: 62,
    name: "Extra Large Dip n Share Box",
    arabic_name: "بوكس المشاركة العملاق",
    category: "Delivery or takeaway",
    ar_category: "دليفري أو تيك اواي",
    price: 35000,
    description:
      "An extra-large selection of tasty dips, perfect for any occasion.",
    image: images.extra_large_dip_n_share_box,
  },
];

export default archocolate;
