import React from 'react'

const NotFound = () => {
    return (
        <div>
            <div
                className='container text-white'><h1>Page NotFound </h1></div>

        </div>
    )
}

export default NotFound
