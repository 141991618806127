import images from "./images";
const arfood = [
    {
      id:1,  name: "Breakfast for 3 Person",
        arabic_name: "فطور لـ 3 أشخاص",
        category: "breakfast",
        ar_category: "فطور",
        description:
            "Chicken fajita crepe - pepperoni pizza - fried eggs - cheese plate - labneh - vegetable plate - pancake with chocolate - bread - tea for 3 people",
        price: "20000",
        image: images.breakfast_for_3_person,
    },
    {
      id:2,  name: "Breakfast for One Person",
        arabic_name: "فطور لشخص واحد",

        category: "breakfast",
        ar_category: "فطور",
        description:
            "Choose a dish from the following: Museo crepe | Madam Crepe | Cheese Crepe | Chicken Fajita Crepe | feta cheese crepe - Fried eggs - small vegetable plate - bread - tea",
        price: "10000",
        image: images.breakfast_for_one_person,
    },
    // {
    //   id:3,  name: "Breakfast for 5 Person",
    //     arabic_name: "فطور لخمس اشخاص",
    //     category: "breakfast",
    //     ar_category: "فطور",
    //     description:
    //         "Honey-labneh-pepperoni pizza-egg-stuffed with meat-blueberry jam-strawberry jam-pancake with chocolate-egg and mortadella-guimar-vegetables-samon-tea-molasses-butter-shallal cheese-hummus with tahini",
    //     price: "50000",
    //     image: images.breakfast_for_5_person,
    // },

    {
      id:4,  name: "Chicken Wrap",
        arabic_name: "راب الدجاج",
        category: "salt crepe",
        ar_category: "كريب مالحة",
        description: "chicken wrap",
        price: "6000",
        image: images.chicken_wrap,
    },
    {
      id:5,  name: "Chicken Caesar",
        arabic_name: "سيزر بالدجاج",
        category: "salt crepe",
        ar_category: "كريب مالحة",
        description:
            "Chicken and Edam cheese, garnished with lettuce and toasted toast and served with Caesar dressing",
        price: "6000",
        image: images.chicken_caesar,
    },

    {
      id:6,  name: "Chicken",
        arabic_name: "الدجاج",
        category: "quesadilla",
        ar_category: "كاسديا",
        description:
            "Mixed cheeses, spinach, fried onions, oregano and mayonnaise",
        price: "7000",
        image: images.chicken,
    },
    {
      id:7,  name: "Smoked Turkey and Cheese",
        arabic_name: "الحبش المدخن والجبنة",

        category: "quesadilla",
        ar_category: "كاسديا",
        description: "Pesto, fried mushrooms, mayonnaise",
        price: "6500",
        image: images.smoked_turkey_and_cheese,
    },

    {
      id:8,  name: "Margarita",
        arabic_name: "مارغريتا",
        category: "tortilla pizza",
        ar_category: "بيتزا تورتيلا",
        description:
            "Tortilla bread covered with mozzarella cheese, parmesan cheese and tomato sauce",
        price: "6000",
        image: images.margarita,
    },
    {
      id:9,  name: "Pepperoni",
        arabic_name: "الببيروني",

        category: "tortilla pizza",
        ar_category: "بيتزا تورتيلا",
        description:
            "Tortilla bread covered with pepperoni, tomato sauce, mozzarella cheese and parmesan",
        price: "7000",
        image: images.pepperoni,
    },
    {
      id:10,  name: "Vegetables",
        arabic_name: "خضروات",

        category: "tortilla pizza",
        ar_category: "بيتزا تورتيلا",

        description:
            "Tortilla bread tossed with sweet pepper, corn, sugar syrup, tomato sauce, mozzarella cheese and",
        price: "6500",
        image: images.vegetables,
    },
    {
      id:11,  name: "Turkey and Cheese",
        arabic_name: "بيتزا حبش",
        category: "tortilla pizza",

        ar_category: "بيتزا تورتيلا",
        description:
            "Tortilla bread topped with smoked turkey slices, fresh mushrooms, tomato sauce, mozzarella cheese and parmesan cheese, garnished with fresh arugula",
        price: "8000",
        image: images.turkey_and_cheese,
    },

];
export default arfood;
