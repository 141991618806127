import React from 'react'
import archocolate from '../../Constants/archocolate';
import Products from "../../Containers/English/English"

const ChocolateL = () => {
    return (
        <Products products={archocolate}>  </Products>

    )
}

export default ChocolateL