import React from 'react'
import { arbar } from '../../Constants/index';
import Products from "../../Containers/Arabic/Arabic"

const ShishaR = () => {
  return (
    <Products products={arbar}>  </Products>

  )
}

export default ShishaR