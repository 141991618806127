import React from 'react'
import { arbar } from '../../Constants/index';
import Products from "../../Containers/English/English"

const ShishaL = () => {
  return (
    <Products products={arbar}>  </Products>

  )
}

export default ShishaL