import React from 'react'
import { arfood } from '../../Constants/index';
import Products from "../../Containers/Arabic/Arabic"

const FoodR = () => {
    return (
        <Products products={arfood}>  </Products>

    )
}

export default FoodR