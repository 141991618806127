import React from 'react'
import ardrinks from '../../Constants/ardrinks';
import Products from "../../Containers/English/English"

const DrinksL =() => {
  return (
    <Products products={ardrinks}>  </Products>

  )
}

export default DrinksL