import React from 'react'
import { archocolate } from '../../Constants/index';
import Products from "../../Containers/Arabic/Arabic"

const ChocolateR = () => {
    return (
        <Products products={archocolate}>  </Products>

    )
}

export default ChocolateR
