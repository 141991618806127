import React from 'react'
import { arfood } from '../../Constants/index';
import Products from "../../Containers/English/English"

const FoodL = () => {
    return (
        // <div className="wrapper">
            <Products products={arfood}>  </Products>

        // </div>

    )
}

export default FoodL