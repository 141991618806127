import fr1 from "../../assets/images/dnd/fr1.jpg";
import fr2 from "../../assets/images/dnd/fr2.jpg";
import fr3 from "../../assets/images/dnd/fr3.jpg";
import fr4 from "../../assets/images/dnd/fr4.jpg";
import fr5 from "../../assets/images/dnd/fr5.jpg";
import fr6 from "../../assets/images/dnd/fr6.jpg";
import fr7 from "../../assets/images/dnd/fr7.jpg";
import fr8 from "../../assets/images/dnd/fr8.jpg";
import fr9 from "../../assets/images/dnd/fr9.jpg";
import fr10 from "../../assets/images/dnd/fr10.jpg";
import fr11 from "../../assets/images/dnd/fr11.jpg";
import fr12 from "../../assets/images/dnd/fr12.jpg";
import fr13 from "../../assets/images/dnd/fr13.jpg";
import fr14 from "../../assets/images/dnd/fr14.jpg";
import fr15 from "../../assets/images/dnd/fr15.jpg";
import fr16 from "../../assets/images/dnd/fr16.jpg";
import fr17 from "../../assets/images/dnd/fr17.jpg";
import fr18 from "../../assets/images/dnd/fr18.jpg";
import fr19 from "../../assets/images/dnd/fr19.jpg";
import fr20 from "../../assets/images/dnd/fr20.jpg";
import fr21 from "../../assets/images/dnd/fr21.jpg";
import fr22 from "../../assets/images/dnd/fr20.jpg";
import fr23 from "../../assets/images/dnd/fr23.jpg";
import fr24 from "../../assets/images/dnd/fr24.jpg";
import fr25 from "../../assets/images/dnd/fr20.jpg";
import fr26 from "../../assets/images/dnd/fr26.jpg";
import fr27 from "../../assets/images/dnd/fr27.jpg";
import fr28 from "../../assets/images/dnd/fr28.jpg";
import fr29 from "../../assets/images/dnd/fr29.jpg";

const images = {
    fr1,
    fr10,
    fr11,
    fr12,
    fr13,
    fr14,
    fr15,
    fr16,
    fr17,
    fr18,
    fr19,
    fr2,
    fr20,
    fr21,
    fr22,
    fr23,
    fr24,
    fr25,
    fr26,
    fr27,
    fr28,
    fr29,
    fr3,
    fr4,
    fr5,
    fr6,
    fr7,
    fr8,
    fr9,}

export  default images;