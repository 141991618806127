import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const languages = {
    ar: {
        home: "الرئيسية",
        food: "الاطعمة",
        drinks: "المشروبات",
        chocolate: "الشكولاتة",
        shisha: "الشيشة",
        language: "عربي"
    },
    en: {
        home: "Home",
        food: "Food",
        drinks: "Drinks",
        chocolate: "Chocolate",
        shisha: "Shisha",
        language: "English"
    }
}
const NavMenu = () => {
    const [isEnglish, setIsEnglish] = useState(false);

    function checkLanguage() {
        console.log("isEnglish")
        setIsEnglish(!isEnglish)
    }
    return (

        <ul className="ulist">
            <li className="list">
                {/* <Link to={isEnglish ? navigation("/foodar") : navigation("/fooden")} className="categoryLink" > */}
                <Link to={isEnglish ? "/foodar": "/fooden"} className="categoryLink bton" >

                    {isEnglish ? languages.ar.food : languages.en.food}
                </Link>
            </li>
            <li className="list">
                <Link to={isEnglish ? "/drinksar" : "/drinksen"} className="categoryLink bton" >
                    {isEnglish ? (languages.ar.drinks) : (languages.en.drinks)}
                </Link>
            </li>
            <li className="list">
                <Link to={isEnglish ? "/chocolatear" : "/chocolateen"} className="categoryLink bton" >
                    {isEnglish ? languages.ar.chocolate : languages.en.chocolate}
                </Link>
            </li>
            <li className="list">
                <Link to={isEnglish ? "/shishaar" : "/shishaen"} className="categoryLink bton" >
                    {isEnglish ? languages.ar.shisha : languages.en.shisha}
                </Link>
            </li>
            <li className="btn-lang">
                <Link to="/" className="categoryLink" onClick={() => checkLanguage()}
                >
                    {isEnglish ? languages.en.language : languages.ar.language}
                </Link>
            </li>
        </ul>
    )
}

export default NavMenu