//food
import breakfast_for_3_person from "../assets/images/food/breakfast_for_3_person.jpg";
import breakfast_for_5_person from "../assets/images/food/breakfast_for_5_person.jpg";
import breakfast_for_one_person from "../assets/images/food/breakfast_for_one_person.jpg";
import chicken from "../assets/images/food/chicken.jpg";
import chicken_caesar from "../assets/images/food/chicken_caesar.jpg";
import chicken_wrap from "../assets/images/food/chicken_wrap.jpg";
import margarita from "../assets/images/food/margarita.jpg";
import pepperoni from "../assets/images/food/pepperoni.jpg";
import smoked_turkey_and_cheese from "../assets/images/food/smoked_turkey_and_cheese.jpg";
import turkey_and_cheese from "../assets/images/food/turkey_and_cheese.jpg";
import vegetables from "../assets/images/food/vegetables.jpg";
//drinks
import hot_chocolate from '../assets/images/drinks/hot_chocolate.jpg';
import strawberry from '../assets/images/drinks/strawberry.jpg';
import chocolate_freezy_creamy from '../assets/images/drinks/chocolate_freezy_creamy.jpg';
import cookies_n_cream from '../assets/images/drinks/cookies_n_cream.jpg';
import smores_toasted from '../assets/images/drinks/smores_toasted.jpg';
import speculoos_milk_shake from '../assets/images/drinks/speculoos_milk_shake.jpg';
import popcorn_latte from '../assets/images/drinks/popcorn_latte.jpg';
import spanish_latte from '../assets/images/drinks/spanish_latte.jpg';
import creme_brulee_latte from '../assets/images/drinks/creme_brulee_latte.jpg';
import oriental_latte from '../assets/images/drinks/oriental_latte.jpg';
import speculoos_latte from '../assets/images/drinks/speculoos_latte.jpg';
import classic_latte from '../assets/images/drinks/classic_latte.jpg';
import rose_latte from '../assets/images/drinks/rose_latte.jpg';
import iced_coffee_mocha from '../assets/images/drinks/iced_coffee_mocha.jpg';
import double_coffee_vanilla_latte from '../assets/images/drinks/double_coffee_vanilla_latte.jpg';
import iced_Spanish_latte from '../assets/images/drinks/iced_Spanish_latte.jpg';
import iced_cappuccino from '../assets/images/drinks/iced_cappuccino.jpg';
import caramel_macchiato_latte from '../assets/images/drinks/caramel_macchiato_latte.jpg';
import classic_mojitos from '../assets/images/drinks/classic_mojitos.jpg';
import passion_fruit_mojitos from '../assets/images/drinks/passion_fruit_mojitos.jpg';
import mixed_berries_mojitos from '../assets/images/drinks/mixed_berries_mojitos.jpg';
import strawberry_mojitos from '../assets/images/drinks/strawberry_mojitos.jpg';
import peach_mojitos from '../assets/images/drinks/peach_mojitos.jpg';
import orange_mojitos from '../assets/images/drinks/orange_mojitos.jpg';
import chocolate_freezy from '../assets/images/drinks/chocolate_freezy.jpg';
import cookies_n_cream_milkshakes from '../assets/images/drinks/cookies_n_cream_milkshakes.jpg';
import strawberry_milkshakes from '../assets/images/drinks/strawberry_milkshakes.jpg';
import espresso_solo_or_lungo from '../assets/images/drinks/espresso_solo_or_lungo.jpg';
import espresso_doppio from '../assets/images/drinks/espresso_doppio.jpg';
import cafe_americano from '../assets/images/drinks/cafe_americano.jpg';
import cappuccino from '../assets/images/drinks/cappuccino.jpg';
import caramel_macchiato from '../assets/images/drinks/caramel_macchiato.jpg';
import iced_tea from '../assets/images/drinks/iced_tea.jpg';
import lemon_mint from '../assets/images/drinks/lemon_mint.jpg';
import melon_juice from '../assets/images/drinks/melon_juice.jpg';
import mineral_water from '../assets/images/drinks/mineral_water.jpg';
import soft_drink from '../assets/images/drinks/soft_drink.jpg';
import tea from '../assets/images/drinks/tea.jpg';
import energy_drink from '../assets/images/drinks/energy_drink.jpg';
import turkish_coffe from '../assets/images/drinks/turkish_coffe.jpg';
import pomegranate_juice from '../assets/images/drinks/pomegranate_juice.jpg';
import orange_juice from '../assets/images/drinks/orange_juice.jpg';
//chocolate
import alaskan_waffle from "../assets/images/chocholate/alaskan_waffle.jpg";
import banana_wrap_crepe from "../assets/images/chocholate/banana_wrap_crepe.jpg";
import sky_high from "../assets/images/chocholate/sky_high.jpg";
import brownies_crepe from "../assets/images/chocholate/brownies_crepe.jpg";
import brownies_n_cookies_in_a_pan from "../assets/images/chocholate/brownies_n_cookies_in_a_pan.jpg";
import caramel_cheesecake_bars from "../assets/images/chocholate/caramel_cheesecake_bars.jpg";
import cheesecake from "../assets/images/chocholate/cheesecake.jpg";
import chewy_chocolate_chip_cookies from "../assets/images/chocholate/chewy_chocolate_chip_cookies.jpg";
import chocolate_and_fruit_skewers from "../assets/images/chocholate/chocolate_and_fruit_skewers.jpg";
import chocolate_fondant from "../assets/images/chocholate/chocolate_fondant.jpg";
import chocolate_pudding from "../assets/images/chocholate/chocolate_pudding.jpg";
import cinnamon_and_pecan_crepe from "../assets/images/chocholate/cinnamon_and_pecan_crepe.jpg";
import cream_paff_pyramid from "../assets/images/chocholate/cream_paff_pyramid.jpg";
import crispy_chocolate_ice_cream from "../assets/images/chocholate/crispy_chocolate_ice_cream.jpg";
import crispy_crepe from "../assets/images/chocholate/crispy_crepe.jpg";
import crunchy_chocolate_pancakes from "../assets/images/chocholate/crunchy_chocolate_pancakes.jpg";
import crunchy_oreo_waffle from "../assets/images/chocholate/crunchy_oreo_waffle.jpg";
import crunchy_speculoos_crepe from "../assets/images/chocholate/crunchy_speculoos_crepe.jpg";
import crunchy_speculoos_waffle from "../assets/images/chocholate/crunchy_speculoos_waffle.jpg";
import crunchy_triple_chocolate_crepe from "../assets/images/chocholate/crunchy_triple_chocolate_crepe.jpg";
import digestive_crepe from "../assets/images/chocholate/digestive_crepe.jpg";
import dipndip_waffle from "../assets/images/chocholate/dipndip_waffle.jpg";
import dip_n_dip_chocolate_brownie from "../assets/images/chocholate/dip_n_dip_chocolate_brownie.jpg";
import dip_n_dip_crepe from "../assets/images/chocholate/dip_n_dip_crepe.jpg";
import dip_n_dip_pancake from "../assets/images/chocholate/dip_n_dip_pancake.jpg";
import double_oreo_cheesecake from "../assets/images/chocholate/double_oreo_cheesecake.jpg";
import eclair from "../assets/images/chocholate/eclair.jpg";
import extra_large_dip_n_share_box from "../assets/images/chocholate/extra_large_dip_n_share_box.jpg";
import fettuccine_crepe from "../assets/images/chocholate/fettuccine_crepe.jpg";
import fondue_indulgence from "../assets/images/chocholate/fondue_indulgence.jpg";
import frozen_cheesecake_pops from "../assets/images/chocholate/frozen_cheesecake_pops.jpg";
import fruit_cheesecake from "../assets/images/chocholate/fruit_cheesecake.jpg";
import ice_cream_brownie_sundae from "../assets/images/chocholate/ice_cream_brownie_sundae.jpg";
import ice_cream_scoop from "../assets/images/chocholate/ice_cream_scoop.jpg";
import large_dip_n_share_box from "../assets/images/chocholate/large_dip_n_share_box.jpg";
import layered_lotus from "../assets/images/chocholate/layered_lotus.jpg";
import mango_cheesecake from "../assets/images/chocholate/mango_cheesecake.jpg";
import mighty_crepe from "../assets/images/chocholate/mighty_crepe.jpg";
import mighty_oreo_crepe from "../assets/images/chocholate/mighty_oreo_crepe.jpg";
import mighty_speculous_crepe from "../assets/images/chocholate/mighty_speculous_crepe.jpg";
import oh_my_smores from "../assets/images/chocholate/oh_my_smores.jpg";
import oreo_cheesecake from "../assets/images/chocholate/oreo_cheesecake.jpg";
import pain_perdu from "../assets/images/chocholate/pain_perdu.jpg";
import pancake_brownie_club from "../assets/images/chocholate/pancake_brownie_club.jpg";
import pistachio_candy_crepe from "../assets/images/chocholate/pistachio_candy_crepe.jpg";
import profiterole from "../assets/images/chocholate/profiterole.jpg";
import riple_chocolate_mousse from "../assets/images/chocholate/riple_chocolate_mousse.jpg";
import speculoos_pancake from "../assets/images/chocholate/speculoos_pancake.jpg";
import speculoos from "../assets/images/chocholate/speculoos.jpg";
import speculoos_n_cookies_in_a_pan from "../assets/images/chocholate/speculoos_n_cookies_in_a_pan.jpg";
import sticky_pecan_cinnaroll from "../assets/images/chocholate/sticky_pecan_cinnaroll.jpg";
import strawberry_banana_delight from "../assets/images/chocholate/strawberry_banana_delight.jpg";
import strawberry_crispy_chocolate_ice_cream from "../assets/images/chocholate/strawberry_crispy_chocolate_ice_cream.jpg";
import sweet_and_simple from "../assets/images/chocholate/sweet_and_simple.jpg";
import the_chocolate_burj from "../assets/images/chocholate/the_chocolate_burj.jpg";
import the_mega_jar from "../assets/images/chocholate/the_mega_jar.jpg";
import the_rock_star from "../assets/images/chocholate/the_rock_star.jpg";
import triple_chocolate_crepe from "../assets/images/chocholate/triple_chocolate_crepe.jpg";
import triple_chocolate_mousse from "../assets/images/chocholate/triple_chocolate_mousse.jpg";
import triple_chocolate_waffle from "../assets/images/chocholate/triple_chocolate_waffle.jpg";
import waffle_fries from "../assets/images/chocholate/waffle_fries.jpg";
import waffle_stick from "../assets/images/chocholate/waffle_stick.jpg";


//bar

import dipndip from "../assets/images/bar/dipndip.jpg";
import natural from "../assets/images/bar/natural.jpg";





const images = {

   //food

   breakfast_for_3_person,
   breakfast_for_5_person,
   breakfast_for_one_person,
   chicken,
   chicken_caesar,
   chicken_wrap,
   margarita,
   pepperoni,
   smoked_turkey_and_cheese,
   turkey_and_cheese,
   vegetables,


   //drinks
   hot_chocolate,
   strawberry,
   chocolate_freezy_creamy,
   cookies_n_cream,
   smores_toasted,
   speculoos_milk_shake,
   popcorn_latte,
   spanish_latte,
   creme_brulee_latte,
   oriental_latte,
   speculoos_latte,
   classic_latte,
   rose_latte,
   iced_coffee_mocha,
   double_coffee_vanilla_latte,
   iced_Spanish_latte,
   iced_cappuccino,
   caramel_macchiato_latte,
   classic_mojitos,
   passion_fruit_mojitos,
   mixed_berries_mojitos,
   strawberry_mojitos,
   peach_mojitos,
   orange_mojitos,
   chocolate_freezy,
   cookies_n_cream_milkshakes,
   strawberry_milkshakes,
   espresso_solo_or_lungo,
   espresso_doppio,
   cafe_americano,
   cappuccino,
   caramel_macchiato,
   iced_tea,
   lemon_mint,
   melon_juice,
   mineral_water,
   soft_drink,
   tea,
   energy_drink,
   turkish_coffe,
   pomegranate_juice,
   orange_juice,
   //chocolate

   alaskan_waffle,
   banana_wrap_crepe,
   sky_high,
   brownies_crepe,
   brownies_n_cookies_in_a_pan,
   caramel_cheesecake_bars,
   cheesecake,
   chewy_chocolate_chip_cookies,
   chocolate_and_fruit_skewers,
   chocolate_fondant,
   chocolate_pudding,
   cinnamon_and_pecan_crepe,
   cream_paff_pyramid,
   crispy_chocolate_ice_cream,
   crispy_crepe,
   crunchy_chocolate_pancakes,
   crunchy_oreo_waffle,
   crunchy_speculoos_crepe,
   crunchy_speculoos_waffle,
   crunchy_triple_chocolate_crepe,
   digestive_crepe,
   dipndip_waffle,
   dip_n_dip_chocolate_brownie,
   dip_n_dip_crepe,
   dip_n_dip_pancake,
   double_oreo_cheesecake,
   eclair,
   extra_large_dip_n_share_box,
   fettuccine_crepe,
   fondue_indulgence,
   frozen_cheesecake_pops,
   fruit_cheesecake,
   ice_cream_brownie_sundae,
   ice_cream_scoop,
   large_dip_n_share_box,
   layered_lotus,
   mango_cheesecake,
   mighty_crepe,
   mighty_oreo_crepe,
   mighty_speculous_crepe,
   oh_my_smores,
   oreo_cheesecake,
   pain_perdu,
   pancake_brownie_club,
   pistachio_candy_crepe,
   profiterole,
   riple_chocolate_mousse,
   speculoos_pancake,
   speculoos,
   speculoos_n_cookies_in_a_pan,
   sticky_pecan_cinnaroll,
   strawberry_banana_delight,
   strawberry_crispy_chocolate_ice_cream,
   sweet_and_simple,
   the_chocolate_burj,
   the_mega_jar,
   the_rock_star,
   triple_chocolate_crepe,
   triple_chocolate_mousse,
   triple_chocolate_waffle,
   waffle_fries,
   waffle_stick,

   //bar 
   dipndip,
   natural

};

export default images