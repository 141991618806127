import images from "./images";

const bar =  [
        {
           id:1, name: "Dipndip",
            image: images.dipndip,
            arabic_name: "ديب ان ديب",
            ar_category:"شيشة",
            category:"shisha",
            price: 10000
    },
    {
        id: 2, name: "Gum with Mint",
        image: images.dipndip,
        arabic_name: "علك نعناع",
        ar_category: "شيشة",
        category: "shisha", price: 8000
    },
    {
        id: 3, name: "Lemon with Ment",
        image: images.dipndip,
        arabic_name: " ليمون نعناع",
        ar_category: "شيشة",
        category: "shisha", price: 8000
    },
    {
        id: 4, name: "Grapefruit with Mint",
        image: images.dipndip,
        arabic_name: "عنب نعناع",
        ar_category: "شيشة",
        category: "shisha", price: 8000
    },
    {
        id: 5, name: "English",
        image: images.dipndip,
        arabic_name: "انكليزي",
        ar_category: "شيشة",
        category: "shisha", price: 8000
    },
    {
        id: 6, name: "Double Apple",
        image: images.dipndip,
        arabic_name: "تفاحتين",
        ar_category: "شيشة",
        category: "shisha", price: 8000
    },
    {
        id: 7, name: "Melon",
        image: images.dipndip,
        arabic_name: "ركيى",
        ar_category: "شيشة",
        category: "shisha", price: 8000
    },

        // {
        //    id:8, name: "Royal Sweet",
        //     image: images.dipndip,
        //     arabic_name: "رويال سويت ",
        //     ar_category:"شيشة",
        //     category:"shisha",price: 8000
        // },
    //     {
    //        id:9, name: "Diva",
    //         image: images.dipndip,
    //         arabic_name: "ديفا",
    //         ar_category:"شيشة",
    //         category:"shisha",price: 8000
    // },
    
        // {
        //    id:10, name: "Blueberry",
        //     image: images.dipndip,
        //     arabic_name:"بلوبيري",
        //     ar_category:"شيشة",
        //     category:"shisha",price: 8000
        // },
        // {
        //    id:11, name: "Gum Boby",
        //     image: images.dipndip,
        //     arabic_name: "علك بوبي",
        //     ar_category:"شيشة",
        //     category:"shisha",price: 8000
        // },
        // {
        //    id:12, name: "Bountie",
        //     image: images.dipndip,
        //     arabic_name:"باونتي",
        //     ar_category:"شيشة",
        //     category:"shisha",price: 8000
        // },
    
        // {
        //    id:13, name: "Coffee and Heel",
        //     image: images.dipndip,
        //     arabic_name: "قهوة بالهال",
        //     ar_category:"شيشة",
        //     category:"shisha",price: 8000
        // },
        // {
        //    id:14, name: "Natural",
        //     image: images.natural,
        //     arabic_name:"طبيعي",
        //     ar_category:"شيشة",
        //     category:"shisha",price: 20000
        // }
    ]







export default bar